<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-card elevation="2">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>{{ $t('interface.reset_password') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                    prepend-icon="password"
                                    name="password1"
                                    v-model="password1"
                                    label="New password"
                                    type="password"
                            />
                            <v-text-field
                                    prepend-icon="password"
                                    name="password2"
                                    v-model="password2"
                                    label="Confirm new password"
                                    type="password"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="accent"
                               elevation="2"
                               x-large
                               block
                               @click.prevent.once="resetPassword"
                        >{{ $t('interface.reset_password') }}</v-btn>
                    </v-card-actions>
                </v-card>
                <v-alert
                    :value="show_error"
                    type="error"
                >
                    {{ error }}
                </v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
    name: 'forgot-password',
    data: function () {
        return {
            password1: '',
            password2: '',
            show_error: false,
            error: '',
        }
    },
    methods: {
        resetPassword () {
            this.show_error = false;
            if (this.password1 === this.password2) {
                const url = process.env.VUE_APP_API_URL + '/api/password-reset/confirm/'
                axios.post(url, {
                    password: this.password1,
                    token: this.$route.query.code
                })
                .then(() => {
                    // Back to login
                    this.$router.push('/')
                })
                .catch(err => {
                    this.error = "Uh oh, something went wrong.. " + err.message, 
                    this.show_error = true
                })
            } else {
                this.error = "Passwords don't match!"
                this.show_error = true;
            }
        },
    }
}
</script>